/* CSS */
.button-59 {
  align-items: center;
  background-color: #fff;
  border: 2px solid #000;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  display: inline-flex;
  fill: #000;
  width: 100%;
  height: 30;
  justify-content: center;
  letter-spacing: -.8px;
  line-height: 24px;
  outline: 0;
  text-align: center;
  text-decoration: none;
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-59:focus {
  color: #171e29;
}

.button-59:hover {
  border-color: #06f;
  color: #06f;
  fill: #06f;
}

.button-59:active {
  border-color: #06f;
  color: #06f;
  fill: #06f;
}

/********************* NAVBAR ***********************/
.logo{
  height: 90%;
  width: 90%;
  margin: auto;
}

/*---- MENU LINKS ----*/

/********************* MAIN CARD ***********************/
.mainbody {
  margin: auto;
  width: 600px;
  height: auto;
  outline-style: solid;
  outline-width: 4px;
  outline-color: black;
  border-radius: 10px;
  background: rgb(253, 250, 250);
  padding: 20px;
  overflow: auto;
}

/* .container {
  margin: auto;
  width: 50rem;
  height: 50rem;
  outline: dashed 1px black;
} */

.overlay{
  /*background-color:#355a35;*/
  background-image:url('./assets/shmoop.jpg');
  height: 100vh;
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 25px;
  overflow: auto;
}

.overlay::-webkit-scrollbar {
  display: none;
}

.column {
  float: left;
  word-break:normal;
}

.left {
  width: 25%;
}

.right {
  width: 75%;
}

.flexbox {
  padding: 5px;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.intro {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
}

.bodytext {
  text-align: justify;
}

.bodytext img {
  width: 100%;
  height: auto;
}